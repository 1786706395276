<template>
  <div class="web_down" :class="$t('languages') == 'EN' ? 'web_down_en' : ''">
    <img
      :src="$t('AaAbordTop.' + $route.query.prev + 'download.bgImg')"
      alt=""
      class="bg_img"
    />
    <div class="det">
      <div class="det_top">
        <img
          :src="$t('AaAbordTop.' + $route.query.prev + 'download.logoImg')"
          alt=""
          class="logo_img"
        />
        <span class="font36  clb100 det_az">{{
          $t("AaAbordTop." + $route.query.prev + "download.title1")
        }}</span>
        <span class="font36  det_go">{{
          $t("AaAbordTop." + $route.query.prev + "download.title2")
        }}</span>
      </div>
      <div class="text_left font18 clb100 det_det">
        {{ $t("AaAbordTop." + $route.query.prev + "download.det") }}
      </div>
    </div>
    <div class="eq">
      <!-- ios 二维码 -->
      <div>
        <img
          :src="$t('AaAbordTop.' + $route.query.prev + 'download.iosImg')"
          alt=""
          class="eq_img"
        />
        <!-- <div class="font24 clb100 eq_title">
          {{ $t("AaAbordTop." + $route.query.prev + "download.iosTitle") }}
        </div> -->
        <a
          :href="
            $route.query.prev === 'online' ? ios_quanqiugou : ios_haohuotong
          "
        >
          <img
            :src="$t('AaAbordTop.' + $route.query.prev + 'download.iosLogo')"
            alt=""
            class="down_logo"
          />
        </a>
      </div>
      <!-- 安卓二维码 -->
      <div class="mr">
        <img
          :src="$t('AaAbordTop.' + $route.query.prev + 'download.androidImg')"
          alt=""
          class="eq_img"
        />
        <!-- <div class="font24 clb100 eq_title">
          {{ $t("AaAbordTop." + $route.query.prev + "download.androidTitle") }}
        </div> -->
        <img
          v-if="isWxClient && isMobileTerminal"
          :src="$t('AaAbordTop.' + $route.query.prev + 'download.androidLogo')"
          alt=""
          class="down_logo"
        />
        <a
          v-else
          :href="
            $route.query.prev === 'online'
              ? android_quanqiugou
              : android_haohuotong
          "
        >
          <img
            :src="
              $t('AaAbordTop.' + $route.query.prev + 'download.androidLogo')
            "
            alt=""
            class="down_logo"
          />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import {
  isIPhoneOrIPad,
  isWxClient,
  isMobileTerminal,
  isAndroid
} from "@/utils/pattern";
export default {
  data() {
    return {
      show: false,
      ios_quanqiugou: "itms-apps://itunes.apple.com/app/1544947865",
      ios_haohuotong: "itms-apps://itunes.apple.com/app/1531836585",
      android_quanqiugou:
        "https://assets-apk.myazstore.com/store-retail/az_android_app_qqg.apk",
      android_haohuotong:
        "https://assets-apk.myazstore.com/store-distributor-new/az_android_app_hht.apk"
      // ios: "itms-apps://itunes.apple.com/app/1531836585",
      // android:
      //   "https://a.app.qq.com/o/simple.jsp?pkgname=com.azgo.globalproducts",
      // android_cdn:
      //   "https://assets-apk.myazstore.com/store-distributor/app-hht-105.apk"
    };
  },
  computed: {
    isIPhoneOrIPad() {
      return isIPhoneOrIPad();
    },
    isWxClient() {
      return isWxClient();
    },
    isMobileTerminal() {
      return isMobileTerminal();
    },
    isAndroid() {
      return isAndroid();
    }
  },
  methods: {
    showGuid() {
      if (!this.isWxClient) {
        return;
      }
      this.show = true;
    },
    closeGuid() {
      this.show = false;
    }
  }
};
</script>
<style lang="less" scoped>
.web_down {
  position: relative;
  height: calc(100vw * 73.4 / 192);
  .bg_img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .det {
    position: absolute;
    top: calc(100vw * 8 / 192);
    left: calc(100vw * 32.4 / 192);
    z-index: 99;
  }
  .det_top {
    display: flex;
    align-items: center;
    .logo_img {
      width: calc(100vw * 11.2 / 192);
    }
    .det_az {
      margin-left: calc(100vw * 1 / 192);
    }
    .det_go {
      color: #bc9268;
    }
  }
  .det_det {
    width: calc(100vw * 73.2 / 192);
    margin-top: calc(100vw * 1.6 / 192);
  }
  .eq {
    position: absolute;
    bottom: calc(100vw * 10 / 192);
    left: calc(100vw * 32.4 / 192);
    z-index: 99;
    display: flex;
    justify-content: flex-start;
    .eq_img {
      width: calc(100vw * 15.1 / 192);
      height: calc(100vw * 15.1 / 192);
      border: 2px solid #000;
      border-radius: 14px;
    }
    .eq_title {
      margin-top: calc(100vw * 2.4 / 192);
    }
  }
  .mr {
    margin-left: calc(100vw * 6 / 192);
  }
}
.down_logo {
  margin: calc(100vw * 2.4 / 192) auto 0;
  display: block;
  width: calc(100vw * 12 / 192);
}
.web_down_en {
  .det_det {
    margin-top: calc(100vw * 0 / 192);
  }
  .det {
    top: calc(100vw * 1 / 192);
  }
  .eq {
    bottom: calc(100vw * 2 / 192);
  }
}
.guid_back {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
}
.guid {
  text-align: right;
  padding: 8px 16px;
}
.guid img {
  width: 120px;
  height: 156px;
}
.mask_c {
  background: #fff;
  margin: 8px 24px auto 24px;
  border-radius: 5px;
  padding: 12px;
  font-size: 14px;
  color: #000;
  text-align: left;
}
</style>
